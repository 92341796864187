export const FirebaseConfig = {
	"projectId": "eselim-ride-app",
	"appId": "1:736688526667:web:595c6d0a6826b7c74bc4ce",
	"databaseURL": "https://eselim-ride-app-default-rtdb.firebaseio.com",
	"storageBucket": "eselim-ride-app.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyB_KhxpVSJvz2sy17LW2ddirtFpE9wbaD0",
	"authDomain": "eselim-ride-app.firebaseapp.com",
	"messagingSenderId": "736688526667",
	"measurementId": "G-EPC7VPR05E"
};